<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :rules="rules" ref="form" :model="form" label-width="200px" style="width:600px">
        <el-form-item prop="app_code" label="schema终端编号">
          <el-select v-model="form.app_code">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="platform" label="平台标识">
          <el-select v-model="form.platform">
            <el-option label="安卓" :value="1"></el-option>
            <el-option label="IOS" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="package_name" label="包名称">
            <el-input v-model="form.package_name"></el-input>
        </el-form-item>
        <el-form-item prop="version_number" label="版本号">
            <el-input v-model="form.version_number"></el-input>
        </el-form-item>
        <el-form-item prop="link" label="版本包的商城连接地址">
            <el-input v-model="form.link"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="is_must" label="强制更新">
            <el-radio-group v-model="form.is_must">
                <el-radio :label="1">强制更新</el-radio>
                <el-radio :label="2">不强制</el-radio>
            </el-radio-group>
        </el-form-item> -->

        <el-form-item label="上传版本包">
          <el-upload
              :on-change="handleChange"
              class="upload-demo"
              :action="s3url"
              :show-file-list="false"
              :http-request="fileUpload"
              @change="handelFileChange"
              :auto-upload="false"
            >
              <el-button size="small" type="info">选取文件</el-button>
            </el-upload>
            <el-progress :percentage="percentage" status="success"></el-progress>
        </el-form-item>
        <el-form-item prop="status" label="状态">
            <el-radio-group v-model="form.status">
                <el-radio-button :label="1">撤销</el-radio-button>
                <el-radio-button :label="2">测试</el-radio-button>
                <el-radio-button :label="3">发布</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <h4 style="margin-bottom:10px;">| 设置多语言版本日志</h4>
        <el-form-item
          v-for="(item,key) in logsitem"
          :label="'语言类型'"
          :key="key"
        >
          <el-select v-model="item.code" placeholder="语言类型">
            <el-option
              v-for="ele in langueList"
              :key="ele.code"
              :label="ele.name"
              :value="ele.code">
            </el-option>
          </el-select>
          <el-input
            style="margin-top:10px"
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="item.log">
          </el-input>
          <el-button style="position: absolute;margin-left: 10px;" size="mini" type="warning" @click.prevent="removeLanguage(item)">删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="addLanguage">新增多语言日志</el-button>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="Validate(onSubmit)">提交</el-button>
        </el-form-item>
    </el-form>
    
  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      form: {
        code: '',
        app_code: '',
        is_must: 2,
        key: '',
        link: '',
        md5: '',
        package_name: '',
        package_size: '',
        platform: '',
        version_number: '',
        log: '',
        status: 1
      },
      rules: {
        app_code: [{ required: true, message: '请输入schema终端编号', trigger: 'blur' }],
        // is_must: [{ required: true, message: '请输入强制更新', trigger: 'blur' }],
        // key: [{ required: true, message: '请输入版本包在s3存储的key名称', trigger: 'blur' }],
        // link: [{ required: true, message: '请输入版本包的商城连接地址', trigger: 'blur' }],
        // md5: [{ required: true, message: '请输入版本包md5信息', trigger: 'blur' }],
        // package_name: [{ required: true, message: '请输入包名称', trigger: 'blur' }],
        package_size: [{ required: true, message: '请输入包大小', trigger: 'blur' }],
        platform: [{ required: true, message: '请输入平台标识', trigger: 'blur' }],
        version_number: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        // log: [{ required: true, message: '请输入版本包更新日志', trigger: 'blur' }]
      },
      contentCode: [],
      soft_code: '',
      applist: [],
      logsitem: [],
      langueList: [],
      upPackage: {},
      s3url: '',
      percentage: 0
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleChange () {
			this.percentage = 0
		},
    GetLanguage () {
      this.api.LanguageList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.langueList = res.data.data.data
          return
        }
        this.$message.error('语言获取失败' + res.data.msg)
      })
    },
    GetClientcode () {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          this.GetLanguage()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.SoftDetail({
        code: this.soft_code
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          let logs = JSON.parse(res.data.data.log)
          for (var key in logs) {
            this.logsitem.push({code: key,log: logs[key]})
          }
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    goback (){
      this.$router.push('/iot/version/soft')
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    onSubmit (){
      let logs = {}
      this.logsitem.forEach(item => {
        if (item.code) {
          logs[item.code] = item.log
        }
      })
      this.form.log = JSON.stringify(logs)
      this.api.SoftUpdate({
        code: this.form.code,
        app_code: this.form.app_code,
        is_must: this.form.is_must,
        key: this.form.key,
        link: this.form.link,
        md5: this.form.md5,
        package_name: this.form.package_name,
        package_size: +this.form.package_size,
        platform: this.form.platform,
        version_number: this.form.version_number,
        log: this.form.log,
        status: this.form.status
      }).then(res => {
        if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '提交成功!'
            })
            return
          }
          this.$message.error('提交失败' + res.data.msg)
      })
    },
    addLanguage() {
      this.logsitem.push({
        name: '',
        code: ''
      });
    },
    removeLanguage (item) {
      var index = this.logsitem.indexOf(item)
      if (index !== -1) {
        this.logsitem.splice(index, 1)
      }
    },
    // 上传
    handelFileChange (event) {
      this.upPackage = event.raw
      console.log(event,'文件')
      this.GetPicturepresign()
    },
    // 预签名
    GetPicturepresign () {
      util.getFileMD5(this.upPackage, md5 => {
        let params = {
          key_action: 'version_app',
          key_md5: md5,
          key_type: 'display',
          key_suffix: this.upPackage.name.split('.')[1],
          content_length: this.upPackage.size,
          content_type: this.upPackage.name.split('.')[1]
        }
        this.form.package_size = this.upPackage.size
        this.form.md5 = md5
        this.GetPreSign(this.upPackage,params)
      })
    },
    GetPreSign (file,params) {
      this.api.S3push(params).then(res => {
        if (res.data.code == 200) {
          this.s3url = res.data.data.put_url
          this.form.key = res.data.data.key
          this.fileUpload(file)
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    fileUpload (file) {
      let config = {
        headers: {
          'Content-Type': this.upPackage.name.split('.')[1],
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        },
        onUploadProgress: progressEvent => {
					let val
					if (progressEvent.lengthComputable) {
						val = parseInt(((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)) 
					}
					this.percentage = val
				}
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      let url = '/aws' + this.s3url.split(urlReg.exec(this.s3url)[0])[1]
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
        }
      }).catch( err => {
        this.$message.error('上传失败:'+err)
      })
    },
  },
  filters: {},
  mounted () {
    this.soft_code = this.$route.params.code
    if (this.soft_code !== '0') {
      this.GetInfo()
    }
  },
  created () {
    this.GetClientcode()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.upload-demo {
  float left
}
.el-progress {
  float right
  line-height 40px
  width 70%
}
</style>
